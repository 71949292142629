import React, { useRef, useState, useEffect } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, Loading, NamedLink } from '../../components';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import config from '../../config';
import icon from './icone-security.png';
import css from './ListingPage.module.css';
import { PAYOUT_STEPS } from '../MangopayPayoutPage/MangopayPayoutPage';
import { PAYMENT_STEPS } from '../MangopayPaymentMethodsPage/MangopayPaymentMethodsPageOld';

const SectionHeading = props => {
  const {
    priceTitle,
    formattedPrice,
    richTitle,
    category,
    authorLink,
    showContactUser,
    onContactUser,
    author,
    currentUser,
    intl,
  } = props;
  const [loading, setLoading] = useState(true);
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
    return () => {
      mounted.current = false;
      setLoading(false);
    };
  }, []);

  const unitType = config.lineItemUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingPage.perNight'
    : isDaily
    ? 'ListingPage.perDay'
    : 'ListingPage.perUnit';

  const authorHasCreatedWallet = author?.attributes?.profile?.metadata?.mangopay_wallet_id;
  const authorHasKYCVerified =
    author?.attributes?.profile?.metadata?.kyc_last_validated_on &&
    author?.attributes?.profile?.metadata?.mangopay_bank_account_id &&
    (author?.attributes?.profile?.metadata?.steps_completed ?? []).includes(
      PAYOUT_STEPS.KYC_SUCCEEDED
    );
  const userHasCreatedWallet =
    currentUser?.attributes?.profile?.metadata?.payer_mangopay_user_id &&
    currentUser?.attributes?.profile?.metadata?.payer_mangopay_wallet_id;

  const userStepsCompleted = (
    currentUser?.attributes?.profile?.metadata?.payer_steps_completed ?? []
  ).includes(PAYMENT_STEPS.ADD_CARD);

  const validationMessage = !authorHasCreatedWallet
    ? 'OrderPanel.authorHasNotCreatedWallet'
    : // : !authorHasKYCVerified
      // ? 'OrderPanel.authorHasNotKYCVerified'
      null;

  const message =
    currentUser && author
      ? validationMessage
        ? null
        : // intl.formatMessage({ id: validationMessage })
          null
      : null;

  return (
    <div className={css.sectionHeading}>
      <div className={css.desktopPriceContainer}>
        <div className={css.desktopPriceValue} title={priceTitle}>
          {formattedPrice}
        </div>
        <div className={css.desktopPerUnit}>
          <FormattedMessage id={unitTranslationKey} />
        </div>
      </div>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.author}>
          {/* {config.custom.categories?.find(i => i.key == category)?.label?.toLowerCase()}&nbsp; */}
          <FormattedMessage id="ListingPage.soldBy" values={{ name: authorLink }} />
          {showContactUser ? (
            <span className={css.contactWrapper}>
              <span className={css.separator}>•</span>
              <InlineTextButton
                rootClassName={css.contactLink}
                onClick={onContactUser}
                enforcePagePreloadFor="SignupPage"
              >
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null}
        </div>
        <div className={css.container}>
          <div className={css.left}>
            <img src={icon} className={css.icon} alt="icone-security" />
          </div>
          <div className={css.right}>
            <h4 className={css.headingText}>
              {<FormattedMessage id="ListingPage.securePayment" />}
            </h4>
            <p className={css.text}>
              {<FormattedMessage id="ListingPage.securePaymentText1" />}
              {<FormattedMessage id="ListingPage.securePaymentText2" />}
              {<FormattedMessage id="ListingPage.securePaymentText3" />}{' '}
              <NamedLink name="FaqPage">
                <FormattedMessage id="ListingPage.securePaymentText4" />
              </NamedLink>
            </p>
          </div>
        </div>
        <div className={css.feeContainer}>
          <p className={css.fee}>
            {<FormattedMessage id="ListingPage.fee" />}
            <br />
            {<FormattedMessage id="ListingPage.feeText" />}
          </p>
        </div>
        {loading ? (
          <p>
            <Loading />
          </p>
        ) : (
          message && <p className={css.error}>{message}</p>
        )}
      </div>
    </div>
  );
};

export default SectionHeading;
