import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config';
import css from './ListingPage.module.css';
import { useIntl } from 'react-intl';
const SectionFeaturesMaybe = props => {
  const { publicData } = props;
  if (!publicData) {
    return null;
  }
  const {
    fabric_brands,
    patterns,
    weight,
    swatch_condition,
    machine_brands,
    machine_condition,
    notions_condition,
    pattern_brand,
    zipper_type,
    buttons_material,
    button_size,
  } = publicData;
  const intl = useIntl();
  const sectionMachineBrand = machine_brands ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.machineBrand" />
      </h2>
      <div className={css.detailsContent}>
        {intl.formatMessage({
          id: config.custom.machineBrandOptions.find(item => item.key === machine_brands)?.label,
        })}
      </div>
    </div>
  ) : null;
  const sectionMachineCondition = machine_condition ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.machineCondition" />
      </h2>
      <div className={css.detailsContent}>
        {intl.formatMessage({
          id: config.custom.machineAndToolConditions.find(item => item.key === machine_condition)
            ?.label,
        })}
      </div>
    </div>
  ) : null;
  const sectionNotionCondition = notions_condition ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.notionCondition" />
      </h2>
      <div className={css.detailsContent}>
        {intl.formatMessage({
          id: config.custom.notionsConditions.find(item => item.key === notions_condition)?.label,
        })}
      </div>
    </div>
  ) : null;
  const sectionPatternBrand = pattern_brand ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.patternBrand" />
      </h2>
      <div className={css.detailsContent}>
        {intl.formatMessage({
          id: config.custom.patternBrandOptions.find(item => item.key === pattern_brand)?.label,
        })}
      </div>
    </div>
  ) : null;
  const sectionZipperType = zipper_type ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.type" />
      </h2>
      <div className={css.detailsContent}>
        {intl.formatMessage({
          id: config.custom.typeOptions.find(item => item.key === zipper_type)?.label,
        })}
      </div>
    </div>
  ) : null;
  const sectionButtonMaterial = buttons_material ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.buttonMaterial" />
      </h2>
      <div className={css.detailsContent}>
        {intl.formatMessage({
          id: config.custom.materialOptions.find(item => item.key === buttons_material)?.label,
        })}
      </div>
    </div>
  ) : null;
  const sectionButtonSize = button_size ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.buttonSize" />
      </h2>
      <div className={css.detailsContent}>{button_size}mm</div>
    </div>
  ) : null;
  const sectionFabricBrand = fabric_brands ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.fabricBrands" />
      </h2>
      <div className={css.detailsContent}>
        {intl.formatMessage({
          id: config.custom.fabricBrandOptions.find(item => item.key === fabric_brands)?.label,
        })}
      </div>
    </div>
  ) : null;
  const sectionPattern = patterns ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.pattern" />
      </h2>
      <div className={css.detailsContent}>
        {intl.formatMessage({
          id: config.custom.patternOptions.find(item => item.key === patterns)?.label,
        })}
      </div>
    </div>
  ) : null;
  const sectionWeight = weight ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.weight" />
      </h2>
      <div className={css.detailsContent}>
        {intl.formatMessage({
          id: config.custom.weightOptions.find(item => item.key === weight)?.label,
        })}
      </div>
    </div>
  ) : null;
  const sectionSwatchCondition = swatch_condition ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.swatchCondition" />
      </h2>
      <div className={css.detailsContent}>
        {intl.formatMessage({
          id: config.custom.swatchConditionsOptions.find(item => item.key === swatch_condition)
            ?.label,
        })}
      </div>
    </div>
  ) : null;
  return (
    <>
      {sectionFabricBrand}
      {sectionPattern}
      {sectionWeight}
      {sectionSwatchCondition}
      {sectionButtonMaterial}
      {sectionButtonSize}
      {sectionMachineBrand}
      {sectionMachineCondition}
      {sectionNotionCondition}
      {sectionPatternBrand}
      {sectionZipperType}
    </>
    // <div className={css.sectionFeatures}>
    //   <h2 className={css.featuresTitle}>
    //     <FormattedMessage id="ListingPage.featuresTitle" />
    //   </h2>
    // </div>
  );
};

export default SectionFeaturesMaybe;
