import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ListingPage.module.css';
import config from '../../config';
import { useIntl } from 'react-intl';
const SectionDetailsMaybe = props => {
  const { publicData = null } = props;
  if (!publicData) {
    return null;
  }
  const { category, subcategory, subSubCategory } = publicData;
  const intl = useIntl();
  const categoryLabel = intl
    .formatMessage({
      id: config.custom.categories?.find(cat => cat.key === category)?.label ?? '',
    })
    ?.toLowerCase();
  const subCategoryLabel = intl
    .formatMessage({
      id:
        config.custom[`${category}SubCategory`]?.find(cat => cat.key === subcategory)?.label ?? '',
    })
    ?.toLowerCase();
  const subSubCategoryLabel = intl
    .formatMessage({
      id:
        config.custom[`${subcategory}SubSubCategory`]?.find(cat => cat.key === subSubCategory)
          ?.label ?? '',
    })
    ?.toLowerCase();

  return publicData ? (
    <div className={css.sectionDetails}>
      <h2 className={css.detailsTitle}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </h2>
      <ul className={css.details}>
        {category ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              {<FormattedMessage id="ListingPage.categoryTitle" />}
            </span>
            <span className={css.category}>{categoryLabel}</span>
          </li>
        ) : null}
        {subcategory ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              {<FormattedMessage id="ListingPage.subCategoryTitle" />}
            </span>
            <span className={css.category}>{subCategoryLabel}</span>
          </li>
        ) : null}
        {subSubCategory ? (
          <li className={css.detailsRow}>
            <span className={css.detailLabel}>
              {<FormattedMessage id="ListingPage.subSubCategoryTitle" />}
            </span>
            <span className={css.category}>{subSubCategoryLabel}</span>
          </li>
        ) : null}
      </ul>
    </div>
  ) : null;
};

export default SectionDetailsMaybe;
